<template lang="pug">
app-layout
  .page-catalog
    search-bar.mb-4
      search-bar-item.mx-4(
        grow
        width=200
        @click="openSearchDialog"
        :disabled="!initialized"
      )
        ui-icon.c-gray70(name="search" left)
        .t-button-middle.c-gray80.text--truncate(v-if="queryModel") {{ queryModel }}
        .t-button-middle.c-gray60(v-else) Поиск по каталогу

      search-bar-item.mx-4(
        shrink
        width=90
        @click="openFiltersDialog"
        :disabled="!initialized"
      )
        ui-icon.mr-1(:class="filtersCount ? 'c-primary' : 'c-gray70'" name="sliders" left)
        .t-button-middle.c-gray80 Фильтры

    //- router-link(to="map")
    //- div(
    //- style="border: 1px solid #ddd; display: flex; align-items: center; justify-content: center; height: 100px"
    //- ) Показать на карте

    template(v-if="initialized")
      search-preset-saver.px-4(v-if="!fetchingAssets")
      ui-skeleton.mx-4(v-else type="btn-rounded" width=150)

      h2.t-h1-mobile.my-6.mx-4 {{ catalogTitle }}

      template(v-if="assets.length")
        .page-catalog__toolbar.t-button-small.c-text.d-flex.justify-space-between.align-center.px-4
          span {{ totalCount | assetCount }}
          .d-flex.align-center.c-black70(@click="openSortPopup")
            span {{ sort.text }}
            ui-icon(name="arrow-down")
        .page-catalog__items.pa-4
          asset-card.mb-4(
            v-for="asset in assets"
            :key="asset.short_id"
            new-window
            :asset="asset"
          )

          .mt-10.mb-8(v-if="totalCount > assets.length")
            ui-btn.mb-4(
              v-if="!fetchingAssets"
              preset="rounded"
              width="100%"
              height="40px !important"
              @click="loadMoreAssets"
              :disabled="fetchingAssets"
            )
              ui-icon.mr-1(name="renew")
              | Показать ещё
            ui-btn.mb-4(v-else preset="rounded" width="100%")
              ui-loader.mr-1(inline)
              .text--normal Загрузка активов...
            .t-caption-normal.text-center.c-black60 Показано {{ assets.length }} из {{ totalCount }}

          .mt-8(v-if="feedbackFormVisible")
            feedback-form(v-bind="feedbackOptions" @click:close="closeFeedbackForm()")

      .page-catalog__items.page-catalog__items--empty(v-else)
        titled-image.my-10(
          v-if="!fetchingAssets"
          fill
          image="404"
          title="Не найдено активов"
          width=160
        )
          div По вашим параметрам ничего не нашлось. Попробуйте сбросить фильтры.
          ui-btn.mt-6.px-2(secondary @click="resetFilters")
            ui-icon(name="close-2" left)
            | Сбросить фильтры

        template(v-if="fetchingAssets")
          .page-catalog__toolbar.d-flex.justify-space-between.align-center.px-4
            ui-skeleton.text-small(type="text" width=70)
            ui-skeleton.text-small(type="text" width=90)
          .page-catalog__items.pa-4
            ui-skeleton.mb-4(type="asset-card" width="100%" height=430)
            ui-skeleton(type="asset-card" width="100%" height=430)

    v-overlay(:value="!initialized" color="white" opacity="1")
      ui-loader(text="Загрузка каталога...")

    sort-popup(ref="sortPopup" @change="changeSort")
    filters-dialog(ref="filtersDialog" :categories-tree-object="categoriesTree")
    fulltext-search-dialog(
      ref="searchDialog"
      :categories-tree-object="categoriesTree"
      :purpose="purpose"
    )
</template>

<script>
  import { UiIcon, UiBtn, UiLoader, UiSkeleton } from '@/uikit';
  import AppLayout from '@/layouts/AppLayout';
  import SearchBar from '@/components/search/SearchBar.vue';
  import SearchBarItem from '@/components/search/SearchBarItem.vue';
  const AssetCard = () => import('@/components/asset/AssetCard.vue');
  const FeedbackForm = () => import('@/components/feedback/FeedbackForm.vue');
  const FiltersDialog = () => import('@/components/search/FiltersDialog.vue');
  const FulltextSearchDialog = () => import('@/components/search/FulltextSearchDialog.vue');
  const SearchPresetSaver = () => import('@/components/search/SearchPresetSaver.vue');
  const SortPopup = () => import('@/components/SortPopup.vue');
  const TitledImage = () => import('@/components/TitledImage.vue');

  import { service as API } from '@/services/assets';
  import { handleError } from '@/libs/handle-error';

  import { createLogger } from '@/uikit/util/logger';
  import { CategoriesTree } from '@/services/assets/categories-tree';
  import { AssetQuery } from '@/services/assets/asset-query';

  const logger = createLogger('PageCatalog', 'blue');

  export default {
    name: 'page-catalog',

    components: {
      AppLayout,
      AssetCard,
      FeedbackForm,
      FiltersDialog,
      FulltextSearchDialog,
      SearchBar,
      SearchBarItem,
      SearchPresetSaver,
      SortPopup,
      TitledImage,
      UiBtn,
      UiIcon,
      UiLoader,
      UiSkeleton,
    },

    props: {
      assetPurpose: { type: String, default: 'buy' },
      assetKind: { type: String, default: undefined },
      query: { type: String, default: undefined },
    },

    data: () => ({
      catalogInitialized: false,
      fetchingAssets: false,
      fetchingCategories: false,
      categoriesTree: undefined,
      sort: { value: 'priority_desc', text: 'По умолчанию' },
      feedbackFormVisible: false,
      feedbackOptions: {
        action: 'mobile_search',
        rateText: 'Оцените удобство поиска в мобильной версии',
      },
    }),

    computed: {
      initialized() {
        return this.catalogInitialized && this.$store.state.catalog?.initialized;
      },

      currentUser() {
        return this.$store.getters['currentUser'];
      },

      kind() {
        return this.$store.state.catalog?.kind;
      },

      purpose() {
        return this.$store.state.catalog?.placementPurpose;
      },

      isPreinitialized() {
        return this.$store.state.catalog?.preInitialized;
      },

      queryModel: {
        set(value) {
          this.$store.dispatch('catalog/set_query', value);
        },
        get() {
          return this.$store.state.catalog.query;
        },
      },

      searchAddresses() {
        return this.$store.getters['catalog/addresses'];
      },

      catalogTitle() {
        if (!this.kind) {
          return this.purpose === 'buy' ? 'Покупка' : 'Аренда';
        } else {
          const category = this.$store.getters['categories/find'](this.kind);
          return category ? category.title : '';
        }
      },

      assets() {
        return this.$store.getters['catalog/assets'];
      },

      totalCount() {
        return this.$store.state.catalog.total;
      },

      searchParams() {
        return this.$store.getters['catalog/search_params'];
      },

      filtersList() {
        return this.$store.getters['catalog/all_filters_list'];
      },

      filtersCount() {
        let count = 0;
        if (this.searchAddresses && this.searchAddresses.length > 0) count++;
        if (this.searchParams.filters) count += this.searchParams.filters.length;
        return count;
      },
    },

    watch: {
      // '$route.query.search_id'() {
      //   this.initializeCatalog();
      // },

      // '$route.query.share_id'() {
      //   this.initializeCatalog();
      // },

      assetKind(newValue) {
        this.$store.dispatch('catalog/set_kind', newValue);
      },

      assetPurpose(newValue) {
        this.$store.dispatch('catalog/set_purpose', newValue);
      },
    },

    mounted() {
      logger.log('Монтируем компонент каталога');
      this.initialize();
    },

    methods: {
      async initialize() {
        logger.log('>>> Инициализация');

        try {
          // приоритетно переприсваиваем готовые поисковые данные из хранилища
          if (this.isPreinitialized) {
            logger.log('Берем готовые данные из хранилища...', this.searchParams);
            this.initializeCatalog(this.searchParams);
            return;
          }

          // поисковый пресет
          const presetParams = await this.fetchPresetData(
            this.$route.query.search_id,
            this.$route.query.share_id
          );
          if (presetParams) {
            logger.log('Берем настройки из пресета...', presetParams);
            this.initializeCatalog(presetParams);
            return;
          }

          // дефолтные параметры из урла
          logger.log('Формируем дефолтные параметры из урла...');
          this.initializeCatalog({ kind: this.assetKind, purpose: this.assetPurpose });
        } catch (error) {
          logger.error(error);
        }
        logger.log('<<< Инициализация завершена');
      },

      async initializeCatalog(searchData) {
        logger.log('>>> Инициализация каталога', searchData);
        this.catalogInitialized = false;

        // ресетим хранилище в начальное состояние
        logger.log('Ресет хранилища в дефолтное состояние...');
        this.$store.dispatch('catalog/set_addresses', []);
        this.$store.dispatch('catalog/reset_filters_values');

        try {
          const assetQuery = new AssetQuery(searchData);
          const filtersData = await API.getFilters(assetQuery.kind, assetQuery.purpose);
          assetQuery.buildFilters(filtersData);
          assetQuery.assignFromCriterias(searchData.filters);
          logger.log('Сформированные параметры:', searchData, 'assetQuery:', assetQuery.toJSON());

          if (!this.categoriesTree) this.fetchCategories(assetQuery.purpose);

          await this.$store.dispatch('catalog/initialize', {
            placementPurpose: assetQuery.purpose,
            query: assetQuery.query,
            kind: assetQuery.kind,
            page: assetQuery.page,
            addresses: assetQuery.addresses,
            filters: filtersData,
            filtersValues: assetQuery.getCriterias(),
          });
          await this.fetchAssets();
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.catalogInitialized = true;
          // теперь норм
          this.$store.dispatch('catalog/setPreinitialized', false);
        }
        logger.log('<<< Инициализация каталога закончена');
      },

      async fetchPresetData(searchId, shareId) {
        try {
          let preset = undefined;
          let searchData = undefined;
          if (searchId) {
            logger.log('Формируем параметры из searchId...');
            preset = await API.getSearchPresetById(searchId);
          } else if (shareId) {
            logger.log('Формируем параметры из shareId...');
            preset = await API.getSearchPresetByShareId(shareId);
          }

          if (preset && preset.search_params) {
            searchData = { purpose: preset.search_params.placement_purpose };
            searchData = Object.assign(
              {},
              searchData,
              preset.search_params.search ? preset.search_params.search : {}
            );
          }
          return searchData;
        } catch (error) {
          this.$router.push({
            name: 'pageCatalog',
            params: {
              assetKind: this.$route.params.assetKind,
              assetPurpose: this.$route.params.assetPurpose,
            },
          });
          this.$notify.error('Не удалось восстановить параметры поиска');
          handleError(error, logger);
        }
      },

      async fetchCategories(purpose) {
        try {
          logger.log(`Тянем категории ${purpose}...`);
          this.fetchingCategories = true;
          const categories = await API.getCategories(purpose);
          this.categoriesTree = new CategoriesTree(categories);
          this.$store.dispatch('categories/initialize', categories);
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingCategories = false;
        }
      },

      async fetchAssets(searchParams = undefined) {
        try {
          this.fetchingAssets = true;
          const params = searchParams || this.searchParams;
          logger.log('Ищем активы', params);
          const { assets, total } = await API.findAssets(params, this.assetPurpose);
          this.$store.dispatch('catalog/setAssets', { assets, total });
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingAssets = false;
        }
      },

      async loadMoreAssets() {
        if (this.fetchingAssets) return;

        try {
          this.fetchingAssets = true;
          await this.$store.dispatch('catalog/next_page');
          const { assets, total } = await API.findAssets(this.searchParams, this.assetPurpose);
          this.$store.dispatch('catalog/addAssets', { assets, total });
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.fetchingAssets = false;
          this.showFeedbackForm();
        }
      },

      openSortPopup() {
        this.$refs.sortPopup.openModal(this.sort);
      },

      showFeedbackForm() {
        // показываем фидбек
        // зарегистрированному пользователю
        // на 2 — 4 странице
        // только раз в месяц
        if (
          !!this.currentUser &&
          this.searchParams.page > 1 &&
          this.searchParams.page < 5 &&
          !this.$cookies.get('feedback_mobile_search')
        ) {
          this.feedbackFormVisible = true;
        } else {
          this.feedbackFormVisible = false;
        }
      },

      closeFeedbackForm() {
        this.feedbackFormVisible = false;
        this.$cookies.set('feedback_mobile_search', 'done', '30d');
      },

      changeSort(sort) {
        this.sort = sort;
        this.$store.dispatch('catalog/set_sort', this.sort.value);
        this.fetchAssets();
      },

      resetFilters() {
        this.queryModel = null;
        this.$store.dispatch('catalog/set_addresses', []);
        this.$store.dispatch('catalog/reset_filters_values');
        this.fetchAssets();
      },

      async openFiltersDialog() {
        // если не готово хранилище, пока ничего не делаем
        if (!this.initialized) return;

        const assetQuery = await this.$refs.filtersDialog.open(this.searchParams, this.filtersList);
        if (assetQuery) {
          const params = assetQuery.toJSON();
          await this.initializeCatalog(params);

          await this.$store.dispatch('catalog/setPreinitialized', true);
          this.$router.push({
            name: 'pageCatalog',
            params: { assetKind: params.kind, assetPurpose: params.purpose },
            silent: true,
          });
          logger.log('push на новый url', params.purpose, params.kind);
        }
      },

      async openSearchDialog() {
        // если не готово хранилище, пока ничего не делаем
        if (!this.initialized) return;

        const query = await this.$refs.searchDialog.open(this.queryModel);
        if (query !== false) {
          const assetQuery = new AssetQuery({ query });
          const params = assetQuery.toJSON();

          this.initializeCatalog(params);

          await this.$store.dispatch('catalog/setPreinitialized', true);
          this.$router.push({
            name: 'pageCatalog',
            params: { assetKind: this.kind, assetPurpose: this.purpose },
            silent: true,
          });
          logger.log('push на новый url', params.purpose, params.kind);
        }
      },
    },
  };
</script>

<style lang="scss">
  .page-catalog {
  }

  .page-catalog__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
  }
</style>
